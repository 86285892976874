/**
 * react-brandywine-editor.js specific styles
 * works on top of bootstrap and bootstrap-react
 */
.brandywine-width_stretch .brandywine-width_stretch,
.brandywine-width_large,
.brandywine-width_medium,
.brandywine-width_small {
  width: 100%;
}
.brandywine-responsive-x-padding {
  padding-right: 5%;
  padding-left: 5%;
}
.brandywine-responsive-caption {
  font-size: 0.85rem;
}
.brandywine-responsive-header {
  font-size: 3rem;
}

@media (min-width: 576px) {
  .brandywine-responsive-header {
    font-size: 3.5rem;
  }  
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .brandywine-width_large {
    width: 70%;
  }
  .brandywine-width_medium {
    width: 60%;
  }
  .brandywine-width_small {
    width: 50%;
  }
  .brandywine-responsive-caption {
    font-size: 0.9rem;
  }
  .brandywine-responsive-header {
    font-size: 4rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .brandywine-width_large {
    width: 60%;
  }
  .brandywine-width_medium {
    width: 50%;
  }
  .brandywine-width_small {
    width: 40%;
  }
  .brandywine-responsive-x-padding {
    padding-right: 0;
    padding-left: 0;
  }
  .brandywine-responsive-caption {
    font-size: 0.95rem;
  }
  .brandywine-responsive-header {
    font-size: 4.5rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .brandywine-width_large {
    width: 50%;
  }
  .brandywine-width_medium {
    width: 40%;
  }
  .brandywine-width_small {
    width: 30%;
  }
}
